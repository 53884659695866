<template>
    <router-link :to="{ name: type === 'discussion' ? 'GroupDetailDiscussion' : 'GroupDetailTimeline', params: { slug: type === 'discussion' ? data.slug : data.id } }" class="discuss-logo">
        <div class="job-details search-item card mb-3">
            <img :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')">
            <div class="job-description">
                <p class="m-0"><strong>{{ data.author.fullname }}</strong></p>
                <p class="subtitle"><span style="text-transform: capitalize;">{{ type }}</span> . <span class="small">{{ formatDateFromNow(data.created_at) }}</span></p>
                <p>{{ data.title }}</p>
                <p class="job-text">{{ data.excerpt }}</p>
            </div>
            <div class="comments">
                <Icon icon="vs:comments" class="d-inline" /> {{ data.comments_count }}
            </div>
        </div>
    </router-link>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        props: {
            type: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                default: () => {}
            }
        },
        components: {
            Icon
        }
    }
</script>
