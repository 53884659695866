<template>
    <div>
        <p class="m-0">Displaying search result for <strong>{{ $route.query.q }}</strong></p>

        <div class="job-list mt-4" v-if="data.length > 0">
            <div v-for="(item, index) in data" :key="index" :data="item">
                <search-group-item :type="item.resource_type" :data="item" />
            </div>
        </div>
        <div class="mt-10" v-else>
            <empty>
                <p class="pt-2">Search result is empty</p>
            </empty>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import SearchGroupItem from '@/components/SearchGroupItem'
    import Empty from '@/components/Empty'

    export default {
        components: { Empty, SearchGroupItem },
        data () {
            return {
                loading: false,
                data: []
            }
        },
        methods: {
            ...mapActions('groups', ['search']),
            searchGroup ({ q, order }) {
                this.loading = true
                this.search({
                    slug: this.$route.params.code,
                    params: { q, order }
                })
                    .then((data) => {
                        this.data = [...data]
                        this.$forceUpdate()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            fetchData () {
                this.searchGroup({
                    ...this.$route.query
                })
            }
        },
        watch: {
            $route: 'fetchData'
        },
        created () {
            this.fetchData()
            this.$eventHub.on('GROUP_CONTENT_SEARCH', this.searchGroup)
        }
    }
</script>
